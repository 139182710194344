$(window).load(function(){

	function init(){
		slider();

		if($('#splash')){

			if($(window).width() <= 768){
				$('#splash .container').css('padding-top', 0);
				$('.bg').height($('.container').height());
			}else{
				$('#splash .container').css('padding-top', ($(window).height()-$('.option').height())/2);
			}

			$(window).resize(function(){
				if($(window).width() <= 768){
					$('.bg').height($('.container').height());
					$('#splash .container').css('padding-top', 0);
				}else{
					$('.bg').height('100vh');
					$('#splash .container').css('padding-top', ($(window).height()-$('.option').height())/2);
				}
			});
		}

		$('.option').click(function(){
			window.location.href = $(this).find('a').attr('href');
		});

		$('#products .overview > section').click(function(){
			window.location.href = $(this).find('a').attr('href');
		});

		$('#products .related > article').click(function(){
			window.location.href = $(this).find('a').attr('href');
		});

	}

	function slider(){
		$("#flexslider").flexslider({
			animation: 'fade',
			controlNav: true,
			directionNav: false,
			slideshowSpeed: 3000,
			animationLoop: true,
			start: function(slider){
				$($('.flex-control-nav li')[0]).addClass('flex-active');
			},
			before: function(slider){
				$($('.flex-control-nav li')[slider.currentSlide]).removeClass('flex-active');
				$($('.flex-control-nav li')[slider.animatingTo]).addClass('flex-active');
			},
			after: function(slider){
				slider.pause();
				slider.play();
			}
		});
	}

	init();
});


